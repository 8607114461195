import React, { useEffect, useState } from 'react';
import SadaqahJariyahUk from '../../../../components/general/sadaqahJariyahUk';
import { getProducts } from '../../../../services/content';
import { selectProducts } from '../../../../store/products';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../store/user';
import { fontTypeMatcher } from '../../../../utils/helperFunctions';

const sadaqahJariyahUkRamadanStyle = {
  subTitle: '!text-left !sm:text-xl !px-0 !py-0 mb-8',
  name: `text-[#253B7E] uppercase font-bold`,
  description: '!text-[14px]- leading-4',
  body: 'flex-col-reverse md:flex-row-reverse !py-4 !px-4',
  photoFrame: 'md:flex-none md:flex-[0_0_300px]',
  photo: 'w-full md:max-w-[300px]',
  desktopButtonGroup: 'flex !justify-start gap-3 lg:flex-nowrap',
  mobileButtonGroup: '!hidden',
  button: 'w-full uppercase !mb-0',
  content: '!pr-0',
};

const TabbedProductsCarousel = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const [activeTabSadaqah, setActiveTabSadaqah] = useState(0);
  const currency = useSelector(selectSelectedCurrency);

  const allProducts = useSelector(state => selectProducts(state));
  const [tabData, setTabData] = useState([]);

  const { data } = props;
  const tabs = data[0]?.tabs ?? [];
  const title = data[0]?.title?.value ?? '';
  const content = data[0]?.content ?? '';
  const titleFont = data[0]?.font_type.value;

  useEffect(() => {
    if (tabs && allProducts?.length > 0) {
      const tabProductPromises = [];
      tabs.forEach(tab => {
        const {
          fields: { product },
        } = tab;
        tabProductPromises.push(getProducts(product?.request?.url));
      });
      Promise.all(tabProductPromises).then(res => {
        const updatedTabs = tabs.map((tab, idx) => {
          const product = res[idx].data?.data?.[0];
          if (!product) return tab;
          for (let item of allProducts) {
            for (let inner_item of item.sub_categories) {
              for (let inner_item_product of inner_item.products) {
                if (inner_item_product.creator === product.creator) {
                  tab.product = inner_item_product;
                  return tab;
                }
              }
            }
          }
          return tab;
        });
        setTabData(updatedTabs);
      });
    }
  }, [tabs, allProducts]);

  if (!tabs || tabData.length === 0 || tabs.length === 0) return null;

  /* -------------------------------------------------------------------------- */
  return (
    <section className="py-6 md:py-12 bg-[url('../src/images/ali-banat/sdaqah-jariyah-pattern.png')]">
      <div className="md:container mx-auto w-full px-4 md:px-0">
        <div className="text-[#253B7E]">
          <p className={`cursor-pointer w-full text-center text-[30px] lg:text-[40px] sm:text-7xl ${fontTypeMatcher(titleFont)}`}>{title}</p>
          {content && (
            <p
              className="mt-4 md:mt-6 md:leading-7 md:text-[18px] text-center font-brandingMediumItalic [&_*]:!bg-transparent"
              dangerouslySetInnerHTML={{ __html: content.value }}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-3 items-center justify-center my-8">
          {tabs.map(({ fields: { group_name } }, index) => (
            <button
              onClick={() => setActiveTabSadaqah(index)}
              key={`sadaqah_${index}`}
              className={`${
                index === activeTabSadaqah ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
              } rounded-full p-2 w-[150px] uppercase text-[14px] font-['Montserrat'] font-bold`}
            >
              {group_name.value}
            </button>
          ))}
        </div>
        {tabData.map(({ product, fields: { content } }, index) => {
          if (!product) return null;

          return (
            index === activeTabSadaqah && (
              <div key={`sadaqah_pr_${index}`} index={index} className="py-5 px-4 md:px-8 bg-[#14A2DC] rounded-lg ">
                {content?.value && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.value,
                    }}
                  />
                )}
                <SadaqahJariyahUk product={product} currency={currency} customStyle={sadaqahJariyahUkRamadanStyle} anyAmount />
              </div>
            )
          );
        })}
      </div>
    </section>
  );
};
export default TabbedProductsCarousel;
