import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import CustomButton from '../../../components/general/button';
import Sticky from 'react-sticky-el';
import { Link } from 'react-router-dom';

const AppealDetailsTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    showGivingProductsTab,
    givingProducts,
    lang = 'en',
    showAppealProducts,
    handleImpactSelected,
    handleDonorsSelected,
    handleOtherAppealsSelected,
    impactReached,
    donorsReached,
    otherAppealsReached,
    levelGivingReached,
  } = props;
  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const contentRef = useRef(null);

  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      index: 1,
      tabTitle: lang === 'fr' ? 'sauver les orphelins' : lang === 'ar' ? 'إنقاذ الأيتام' : 'Donors',
    },
    {
      index: 2,
      tabTitle: lang === 'fr' ? 'Installation des réfugiés palestiniens' : lang === 'ar' ? 'مستوطنة اللاجئين الفلسطينيين' : 'Impact',
    },
  ];
  /* -------------------------------------------------------------------------- */
  if (showGivingProductsTab) {
    tabButtonsProducts.unshift({
      index: 0,
      tabTitle: lang === 'fr' ? 'Urgence Palestinienne' : lang === 'ar' ? 'فلسطين الطوارئ' : 'Level of Giving',
    });
  }
  if (showAppealProducts) {
    tabButtonsProducts.push({
      index: 3,
      tabTitle: lang === 'fr' ? 'La faim en Palestine' : lang === 'ar' ? 'فلسطين جوعى' : 'Other Appeals',
    });
  }

  const handleTabChange = index => {
    switch (index) {
      case 0:
        window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
        setActiveTabProducts(index);
        break;
      case 1:
        handleDonorsSelected();
        break;
      case 2:
        handleImpactSelected();
        break;
      case 3:
        handleOtherAppealsSelected();
        break;
      default:
        window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
        setActiveTabProducts(index);
    }
    setActiveTabProducts(index);
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    impactReached && setActiveTabProducts(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impactReached]);
  useEffect(() => {
    donorsReached && setActiveTabProducts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donorsReached]);
  useEffect(() => {
    otherAppealsReached && setActiveTabProducts(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherAppealsReached]);
  useEffect(() => {
    levelGivingReached && setActiveTabProducts(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelGivingReached]);

  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]-" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className={`${activeTabProducts === 1 || activeTabProducts === 3 ? '' : 'md:container'} w-full mx-auto px-4 md:px-0 py-4 md:py-12`}>
        <div className="hidden sm:flex items-center w-full overflow-x-auto">
          <div className="flex gap-1 min-[768px]:gap-2 items-center md:justify-center w-full min-w-[500px] max-w-[600px] mx-auto">
            {tabButtonsProducts.map(({ tabTitle, index }) => (
              <CustomButton
                key={`btn_${index}`}
                title={tabTitle}
                onClick={handleTabChange.bind(this, index)}
                className={`${
                  index === activeTabProducts ? '!bg-DarkerBlue text-white' : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue'
                } min-w-fit !rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase mr-1 md:mr-0 ${
                  lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
                } text-[14px]`}
              />
            ))}
          </div>
        </div>
        <div className="flex sm:hidden [&>div]:w-full z-10 bg-white overflow-x-auto">
          <Sticky
            stickyStyle={{ top: '64px', bottom: 'unset' }}
            stickyClassName="!w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_15px_25px_-1px] z-10 bg-white left-0"
            mode="top"
            onFixedToggle={() => {
              setIsTabsFixed(!isTabsFixed);
            }}
          >
            <div
              className={`flex gap-1 items-center w-full mx-auto ${isTabsFixed ? 'justify-around' : 'overflow-x-auto min-w-[500px] max-w-[600px]'}`}
            >
              {tabButtonsProducts.map(({ tabTitle, index }) => (
                <CustomButton
                  key={`btn_${index}`}
                  title={tabTitle}
                  onClick={handleTabChange.bind(this, index)}
                  className={`${
                    isTabsFixed
                      ? index === activeTabProducts
                        ? '!text-[#F60362] !bg-white !px-0'
                        : '!text-[#78716C] !bg-white text-[10px] !px-0 !py-1'
                      : index === activeTabProducts
                      ? '!bg-DarkerBlue text-white !rounded-full p-2 min-w-fit'
                      : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue min-w-fit !rounded-full p-2'
                  }
                     ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'} mr-1- !text-[11px]`}
                />
              ))}
            </div>
          </Sticky>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 md:mt-10">
          <div className="flex flex-col w-full justify-center" ref={contentRef}>
            {activeTabProducts === 0 && <div>{givingProducts}</div>}
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppealDetailsTabs;
