/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import { selectLastOrders, selectProducts } from '../../store/products';
import AppealDetailsTabs from './components/appealDetailTabs';
import AppealLevelGiving from './components/appealLevelGiving';
import AppealDonors from './components/appealDonors';
import AppealImpact from './components/appealImpact';
import AppealWidget from './components/appealWidget';
import services from '../../services';
import Layout from '../../components/general/layout';
import AppealCurrentProduct from './components/appealCurrentProduct';

import { useNavigate, useParams } from 'react-router-dom';
import { CarouselImages } from '../../components/general/imagesCarousel';
import CustomButton from '../../components/general/button';
import ShowCurrencies from '../../utils/showCurrencies';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CloseIcon } from '../../components/general/customIcons';
import { BASKET_CACHE_KEY, HandleLocalStorage } from '../../utils/handleLocalStorage';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useMemo } from 'react';
import { getYoutubeVideoId } from '../../utils/helperFunctions';

const ProductDetail = ({ lang = 'en', campaign = 'palestine' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const [product, setProduct] = useState();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const impactRef = useRef(null);
  const donorsRef = useRef(null);
  const otherAppealsRef = useRef(null);
  const selectGivingRef = useRef(null);
  const [, , removeProduct] = useHandleAddProduct();
  const navigate = useNavigate();
  const orders = useSelector(state => selectLastOrders(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [givingProducts, setGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [currentgivingProducts, setCurrentGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [donors, setDonors] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [appealProducts, setAppealProducts] = useState([0, 0]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [impactReached, setImpactReached] = useState(false);
  const [levelGivingReached, setLevelGivingReached] = useState(false);
  const [donorsReached, setDonorsReached] = useState(false);
  const [otherAppealsReached, setOtherAppealsReached] = useState(false);
  const [selectedGivingProducts, setSelectedGivingProducts] = useState({
    products: [],
    total: 0,
  });
  const [isMinimized, setIsMinimized] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const [bannerImages, setBannerImages] = useState([]);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const showAppealProducts = appealProducts && appealProducts.length > 0;
  const showGivingProducts = givingProducts && givingProducts.length > 0;
  let { slug } = useParams();

  const {
    videoUrl,
    name,
    altTag,
    titleTag,
    description,
    creator,
    webImage,
    mobileImage,
    detailInformation,
    suggestions,
    bannerGroup,
    appeals,
    document,
  } = product || {};
  const image = isMobile ? mobileImage?.[0] || webImage?.[0] : webImage?.[0];

  const sliderImages = useMemo(() => {
    return bannerImages.map(item => ({
      src: isMobile ? item.mobileImage : item.webImage,
      alt: item.altTag,
      title: item.titleTag,
    }));
  }, [isMobile, bannerImages]);

  const getProduct = async () => {
    try {
      const {
        data: { data },
      } = await services.getProducts(`/products/${slug}?extendedDetails=1`);
      setProduct(data);
    } catch (error) {
      navigate('/');
      console.error('error', error);
    }
  };
  const getGivingProducts = async () => {
    if (product && !suggestions?.request?.url) {
      setGivingProducts([]);
      setCurrentGivingProducts([]);
    }
    if (!suggestions?.request?.url) return;
    try {
      const res = await services.getProducts(suggestions.request.url + '?withGoalProgress=1&withDonationCount=1');
      setGivingProducts(res.data?.data);
      setCurrentGivingProducts(res.data?.data);
    } catch (err) {
      toast.error(err);
    }
  };
  const getAppealProducts = async () => {
    if (product && !appeals?.request?.url) {
      setAppealProducts([]);
    }
    if (!appeals?.request?.url) return;
    try {
      const res = await services.getProducts(appeals.request.url + '?withGoalProgress=1&withDonationCount=1');
      setAppealProducts(res.data?.data);
    } catch (err) {
      toast.error(err);
    }
  };
  const getBannerGroup = async () => {
    if (!bannerGroup?.request?.url) return;
    try {
      const res = await services.getContent(bannerGroup.request.url);
      setBannerImages(res.data?.data ?? []);
    } catch (err) {
      toast.error(err);
    }
  };
  /* ------------------------------ Start methods ----------------------------- */

  const getDonors = async _currency => {
    let req = {
      products: [],
      limit: 20,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
    };
    campaign === 'palestine'
      ? (req.products = [
          'MA-PAL-GL-1-001',
          'MA-PAL-GL-1-002',
          'MA-PAL-GL-1-003',
          'MA-PAL-GL-1-004',
          'MA-PAL-GL-1-005',
          'MA-PAL-GL-1-006',
          'MA-PAL-GL-1-007',
          'MA-PAL-GL-1-008',
          'MA-PAL-GL-1-009',
        ])
      : campaign === 'egyptian'
      ? (req.products = ['MA-PAL-GL-3-001', 'MA-PAL-GL-3-002', 'MA-PAL-GL-3-003', 'MA-PAL-GL-3-004', 'MA-PAL-GL-3-005', 'MA-PAL-GL-3-006'])
      : (req.products = ['EMR-PAL-MASU-003', 'EMR-PAL-MASU-005', 'EMR-PAL-MASU-006', 'EMR-PAL-MASU-009']);
    try {
      const response = await services.getLatestDonors(req);
      setDonors(response.data);
    } catch (error) {
      console.error('error========', error);
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempSelectedGiving = [];
    let tempTotalGiving = 0;
    let temp = [];
    givingProducts.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
            tempTotalGiving += (item.quantity || 1) * (item.prices.find(i => i.name === selectedCurrencyStore).data.amount ?? 0);
            tempSelectedGiving.push(item);
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });
    tempSelectedGiving.length === 1 && setIsMinimized(false);
    setSelectedGivingProducts({ ...selectedGivingProducts, products: tempSelectedGiving, total: tempTotalGiving });
    temp = temp.map(item => item);
    setCurrentGivingProducts(temp);
  };
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  const handleCurrency = (currency, tempBasket) => {
    if (tempBasket.products.length && tempBasket.products[0].product) {
      tempBasket.products.map(item => {
        item.currency = currency;
        item.product.currency = currency;
        item.total = item.quantity * item.product[currency];
        item.amount = item.product[currency];
        return item;
      });
      tempBasket.currency = currency;
      HandleLocalStorage(tempBasket);
    } else {
      tempBasket.currency = currency;
      HandleLocalStorage(tempBasket);
    }
  };
  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.currency !== selectedCurrency) {
      handleCurrency(selectedCurrency, basketStatesFromLocalStorage);
    }
    if (givingProducts[0]) {
      handleSelectedGiving();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingProducts, selectedCurrency]);
  useEffect(() => {
    getDonors(selectedCurrencyStore);
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setDonorsReached(entry.isIntersecting);
        setImpactReached(false);
        setOtherAppealsReached(false);
        setLevelGivingReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (donorsRef.current) {
      observer.observe(donorsRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (donorsRef.current) {
        observer.unobserve(donorsRef.current);
      }
    };
  }, [donorsRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setImpactReached(entry.isIntersecting);
        setDonorsReached(false);
        setOtherAppealsReached(false);
        setLevelGivingReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (impactRef.current) {
      observer.observe(impactRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (impactRef.current) {
        observer.unobserve(impactRef.current);
      }
    };
  }, [impactRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setOtherAppealsReached(entry.isIntersecting);
        setImpactReached(false);
        setDonorsReached(false);
        setLevelGivingReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (otherAppealsRef.current) {
      observer.observe(otherAppealsRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (otherAppealsRef.current) {
        observer.unobserve(otherAppealsRef.current);
      }
    };
  }, [otherAppealsRef]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setLevelGivingReached(entry.isIntersecting);
        setImpactReached(false);
        setDonorsReached(false);
        setOtherAppealsReached(false);
      },
      {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger callback when 10% of the element is visible
      },
    );
    if (selectGivingRef.current) {
      observer.observe(selectGivingRef.current);
    }
    // Cleanup function to unobserve the target element
    return () => {
      if (selectGivingRef.current) {
        observer.unobserve(selectGivingRef.current);
      }
    };
  }, [selectGivingRef]);

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    if (!product) return;
    getGivingProducts();
    getAppealProducts();
    getBannerGroup();
  }, [product]);

  /* -------------------------------------------------------------------------- */
  return (
    <Layout className="!bg-white" isAppealPage hideChatIcon>
      <Helmet>
        <title>{`${titleTag} | MATW Project`}</title>
        <meta name="title" content={`${titleTag} | MATW Project`} />
        <meta name="description" content={`${description ?? ''}`} />
        <div itemscope itemtype="http://schema.org/Product">
          <meta itemprop="brand" content="MATW-Project" />
          <meta itemprop="name" content={`${name ?? ''} - MATW Project`} />
          <meta itemprop="description" content={`${description ?? ''}`} />
          <meta itemprop="productID" content={`${creator ?? ''}`} />
          <meta itemprop="url" content={window.location.href} />
          <meta itemprop="image" content={webImage?.[0] ?? ''} />
          <meta itemprop="price" content={'100'} />
        </div>
      </Helmet>
      <AppealCurrentProduct product={product} campaign={campaign} />
      {/* ------------------- Products Section -------------------- */}
      <div ref={selectGivingRef}>
        <AppealDetailsTabs
          showAppealProducts={showAppealProducts}
          showGivingProductsTab={showGivingProducts}
          givingProducts={
            <AppealLevelGiving
              givingProducts={currentgivingProducts}
              title="Select a Giving Level"
              isDetailsPage
              campaign={campaign}
              handleSelectedGivingProps={() => handleSelectedGiving()}
            />
          }
          donors={<AppealDonors donors={donors} currency={selectedCurrency} usedOnTab />}
          impact={<AppealImpact document={document} usedOnTab />}
          lang={lang}
          impactReached={impactReached}
          donorsReached={donorsReached}
          otherAppealsReached={otherAppealsReached}
          levelGivingReached={levelGivingReached}
          handleImpactSelected={() => {
            window.scrollTo({ top: impactRef.current.offsetTop - (showGivingProducts ? 400 : 100) });
          }}
          handleDonorsSelected={() => {
            window.scrollTo({ top: donorsRef.current.offsetTop - 100 });
          }}
          handleOtherAppealsSelected={() => {
            window.scrollTo({ top: otherAppealsRef.current.offsetTop - 400 });
          }}
          scrollPosition={scrollPosition}
        />
      </div>
      {/* /* --------------------------------- Youtube -------------------------------- */}
      <section className="w-full md:container md:mx-auto py-4 md:py-8 px-4 md:px-0">
        <div className="relative overflow-hidden flex flex-wrap">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <div className={`basis-full min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[890px]`}>
            {videoUrl ? (
              <iframe
                className="video"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${getYoutubeVideoId(videoUrl)}?autoplay=0&feature=oembed`}
              />
            ) : image ? (
              <LazyLoadImage
                alt={altTag}
                title={titleTag}
                effect="blur"
                width={`100%`}
                delayTime={500}
                className="rounded"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/general/logo-replace.png';
                }}
                src={image}
              />
            ) : (
              <img className="w-full" src="/images/general/logo-replace.png" alt="" />
            )}
          </div>
        </div>
      </section>

      {/* /* ---------------------------------- Text ---------------------------------- */}
      <section className="md:container md:mx-auto px-4 md:px-0 py-8 md:py-12" dangerouslySetInnerHTML={{ __html: detailInformation }}></section>
      {/* /* --------------------------------- Impacts -------------------------------- */}
      <div id="impacts" ref={impactRef}>
        <AppealImpact />
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------------- carousel section ------------------------------------ */}
      {sliderImages.length > 0 && (
        <section className="mt-4 md:mt-12 mb-6">
          <CarouselImages carouselImages={sliderImages} />
        </section>
      )}
      {/* /* -------------------------------------------------------------------------- */}
      {/* /* ------------------------------ Appeal Widget ----------------------------- */}
      {showAppealProducts && (
        <div id="other_appeals" ref={otherAppealsRef}>
          <AppealWidget appealProducts={appealProducts} title={'MATW Appeals'} />
        </div>
      )}
      {/* /* --------------------------------- Donors --------------------------------- */}
      <div id="donors" ref={donorsRef}>
        <AppealDonors donors={donors} currency={selectedCurrency} />
      </div>
      {selectedGivingProducts.products.length ? (
        <div className="fixed bottom-0 sm:hidden- [&>div]:w-full z-20- z-[2200000000] shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] w-full left-0 p-2 bg-LighterBlue">
          <div className="flex flex-col gap-1 items-center w-full justify-around mx-auto p-2 rounded-lg bg-white">
            <div
              className={`flex w-full flex-col border-b border-stone-300 ${isMinimized ? '' : 'mb-1'} pb-1 cursor-pointer`}
              onClick={handleMinimize}
            >
              <h2 className={`text-DarkerBlue text-lg md:text-2xl w-full font-bold w-100 flex justify-between`}>
                Your Giving Amount{' '}
                <ChevronDownIcon
                  className={`w-4 h-4 cursor-pointer text-stone-600 transition-all duration-300 ${isMinimized ? ' transform rotate-180' : ''}`}
                />
              </h2>
              <div className="flex items-center">
                <span className="text-DarkerBlue text-sm">{selectedGivingProducts.products.length} items in Cart</span>
                <span className="text-LightBlue text-sm cursor-pointer ml-2" onClick={() => navigate('/checkout')}>
                  View Cart
                </span>
              </div>
            </div>
            <div className={`transition-all duration-300 w-full ${isMinimized ? 'h-0' : 'h-auto'} overflow-hidden`}>
              {/* <div className={`transition-all duration-300 overflow-hidden w-full`} style={{ height: `${isMinimized ? '0px' : `${(selectedGivingProducts.products.length > 1 ? selectedGivingProducts.products.length * 32 : selectedGivingProducts.products.length * 36) + 29}px`}` }}> */}
              <div className="flex justify-between items-center flex-col py-1 w-full border-b border-stone-200 max-h-[160px] overflow-y-auto">
                {selectedGivingProducts.products.map((item, index) => {
                  if (showMoreItems) {
                    return (
                      <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                        <div className="text-stone-400 text-xs flex items-center">
                          <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                          <p className="text-stone-400 text-xs">{item.name}</p>
                        </div>
                        <p className="text-xs text-DarkerBlue">
                          {ShowCurrencies(
                            selectedCurrencyStore,
                            item.quantity * (item.prices.find(i => i.name === selectedCurrencyStore).data.amount ?? 0),
                            false,
                          )}
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      index < 2 && (
                        <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                          <div className="text-stone-400 text-xs flex items-center">
                            <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                            <p className="text-stone-400 text-xs">{item.name}</p>
                          </div>
                          <p className="text-xs text-DarkerBlue">
                            {ShowCurrencies(
                              selectedCurrencyStore,
                              item.quantity * (item.prices.find(i => i.name === selectedCurrencyStore).data?.amount ?? 0),
                              false,
                            )}
                          </p>
                        </div>
                      )
                    );
                  }
                })}
              </div>
              {selectedGivingProducts.products.length > 2 && (
                <div className="flex border-b border-stone-200 pb-2">
                  <span className="text-LightBlue text-xs flex items-center cursor-pointer mt-1" onClick={() => setShowMoreItems(!showMoreItems)}>
                    {showMoreItems ? (
                      <>
                        Show less items <ChevronDownIcon className={`w-3 h-3 text-stone-500 ml-2`} />
                      </>
                    ) : (
                      <>
                        View more items <ChevronUpIcon className={`w-3 h-3 text-stone-500 ml-2`} />
                      </>
                    )}
                  </span>
                </div>
              )}
              <div className="flex justify-between items-center border-t border-stone-300 py-1 w-full">
                <p className="text-stone-500 text-xs">Total Amount</p>
                <p className="text-sm font-bold text-DarkerBlue">{ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}</p>
              </div>
            </div>
            <div className="flex items-center mt-1 w-full">
              <CustomButton
                title={
                  <>
                    {`Give `}
                    {ShowCurrencies(selectedCurrencyStore, selectedGivingProducts.total, false)}
                  </>
                }
                link={'/checkout'}
                className={`${lang === 'ar' ? '' : 'font-["Montserrat"]'} font-bold w-full`}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Layout>
  );
};
// export const startDateOfAppealCampaign = new Date().toISOString().split('T')[0];
// export const startDateOfAppealCampaign = moment().format('YYYY-MM-DD') === '2024-09-04' ? '2024-09-04' : '2024-09-05' ;
export const startDateOfAppealCampaign = '2024-09-05';
export const endDateOfAppealCampaign = '2024-10-05';
export const endDateOfLimbsAppealCampaign = '2024-10-15';
export const appealProductsCodes = [
  'MA-PAL-GL-1-001',
  'MA-PAL-GL-1-002',
  'MA-PAL-GL-1-003',
  'MA-PAL-GL-1-004',
  'MA-PAL-GL-1-005',
  'MA-PAL-GL-1-006',
  'MA-PAL-GL-1-007',
  'MA-PAL-GL-1-008',
  'MA-PAL-GL-1-009',
  // 'MA-PAL-GL-2-001',
  // 'MA-PAL-GL-2-002',
  // 'MA-PAL-GL-2-003',
  // 'MA-PAL-GL-2-004',
  // 'MA-PAL-GL-2-005',
  // 'MA-PAL-GL-2-006',
  // 'MA-PAL-GL-2-007',
  // 'MA-PAL-GL-2-008',
  // 'MA-PAL-GL-2-009',
  'MA-PAL-GL-3-001',
  'MA-PAL-GL-3-002',
  'MA-PAL-GL-3-003',
  'MA-PAL-GL-3-004',
  'MA-PAL-GL-3-005',
  'MA-PAL-GL-3-006',

  'EMR-PAL-MASU-003',
  'EMR-PAL-MASU-005',
  'EMR-PAL-MASU-006',
  'EMR-PAL-MASU-009',
];

export default ProductDetail;
