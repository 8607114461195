import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency, setSelectedCurrencyInStore } from '../../store/user';
import SelectCurrency from '../../components/general/selectCurrency';
import ReactSelect from 'react-select';
import useHandleAddProduct from '../../utils/handleAddProduct';
import ShowCurrencies from '../../utils/showCurrencies';
import { getCurrencies } from '../../services/content';
import { selectProducts } from '../../store/products';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const DetailsWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { defaultProduct, isLoaded } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState('');
  const [currentCurrency, setCurrentCurrency] = useState();
  const [addProduct] = useHandleAddProduct();
  const [currencies, setCurrencies] = useState([]);
  const dispatch = useDispatch();
  const isAnyAmount = defaultProduct.customDonation;
  console.log('defaultProduct', defaultProduct);

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const suggestedRecurringModes = [
    { value: 'one-off', label: 'Single' },
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' },
    { value: 'year', label: 'Yearly' },
  ];
  const [selectedRecurring, setSelectedRecurring] = useState(suggestedRecurringModes[0]);

  const handleAddNewProduct = () => {
    let tempProduct = {
      ...defaultProduct.prices.reduce((prev, val) => ({ ...prev, [val.name.toLowerCase()]: val.data.amount }), {}),
      creator: defaultProduct.creator,
      name: defaultProduct.name,
      description: defaultProduct.description,
      custom_amount: defaultProduct.customDonation ? 'YES' : 'NO',
      image_link: defaultProduct.webImage?.[0],
    };
    let customAmount = Number(productAmount) ? Number(productAmount) : defaultProduct.customDonation ? 100 : 1;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });
    addProduct({
      product: tempProduct,
      currency: currentCurrency,
    });
  };

  const handleCurrency = currency => {
    return ShowCurrencies(
      currency,
      defaultProduct.prices?.find(p => p.name === currency)?.data?.amount ?? defaultProduct[currency?.toLowerCase()],
      false,
    );
  };

  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);

  const handleCurrencyChange = val => {
    setCurrentCurrency(val);
    dispatch(setSelectedCurrencyInStore(val));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (defaultProduct.prices) {
          const res = await getCurrencies(defaultProduct?.prices?.map(item => item.id));
          setCurrencies(
            res.data?.data?.items.map(c => ({
              value: c.name,
              label: (
                <span className="flex items-center">
                  <img src={c.flag} className="w-5 mr-1" />
                  {c.name}
                </span>
              ),
            })),
          );
        } else {
          setCurrencies(undefined);
        }
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full bg-[#E2EFF3] md:bg-transparent rounded-lg md:rounded-none overflow-hidden-">
      <div className="basis-full grid grid-cols-12 gap-2 p-4 md:p-0">
        <div
          className="hidden- flex md:flex order-2 md:order-1 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- items-center justify-center"
          style={styles.shadow}
        >
          <SelectCurrency currencies={currencies} onChangeCurrency={e => handleCurrencyChange(e.value)} classNamePrefix="orphan_select" />
        </div>
        <div
          className="hidden- md:flex md:order-2 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- items-center justify-center rounded-md"
          style={styles.shadow}
        >
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-full"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
        </div>
        <div
          className="hidden- flex md:flex order-3 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- items-center justify-center rounded-md"
          style={styles.shadow}
        >
          {isAnyAmount ? (
            <input
              value={productAmount}
              className="w-full md:w-1/2- h-12 p-2 rounded-md text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
              type="number"
              onChange={e => {
                setProductAmount(e.target.value);
              }}
              placeholder="Enter amount"
            />
          ) : (
            <h2 className="text-[#f60362] col-span-6 text-md md:text-xl text-center font-bold h-6 md:h-7">
              {handleCurrency(currentCurrency)}
              <small className={`uppercase ml-1`}>{currentCurrency}</small>
            </h2>
          )}
        </div>
        <div className="order-4 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- flex flex-col justify-center items-center-">
          <button
            className={`text-white w-full text-lg md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            Donate Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailsWidget;
