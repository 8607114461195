import React, { useEffect, useState } from 'react';
import ProductBox from '../product-box';
import { useKeenSlider } from 'keen-slider/react';
import Arrow from '../../../home/components/tab-arrow';
import MultiProductsBox from '../multi-products-box';

const TabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    products,
    currency,
    showDots = true,
    isPalestineRefugee = false,
    arabic = false,
    lang = 'en',
    hasTarget = false,
    withGroup = false,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  return (
    <>
      <div className="hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4">
        {products &&
          products.map((item, index) => (
            <div key={`products__${index}`} className="h-full basis-[48%] lg:basis-1/3 xl:basis-1/4 max-w-[300px]">
              {withGroup && !!item.groups?.length ? (
                <MultiProductsBox
                  multiProducts={[item, ...item.groups]}
                  staticLink={'/'}
                  title={item.title}
                  description={item.description}
                  image={item.img}
                  arabic={arabic}
                  lang={lang}
                  alt={item?.alt}
                  currency={currency ? currency : 'AUD'}
                  showMultiProductsOnRow
                />
              ) : (
                <ProductBox
                  stateVariableObj={{ productName: item.name }}
                  product={item}
                  arabic={arabic}
                  lang={lang}
                  hasTarget={hasTarget}
                  linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                  isStaticProduct={item.isStaticProduct}
                  staticLink={item.staticLink}
                  staticLinkText={item.staticLinkText}
                  currency={currency ? currency : 'AUD'}
                  titleClasses={`uppercase`}
                  isPalestineRefugee={isPalestineRefugee}
                />
              )}
            </div>
          ))}
      </div>
      <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
        <div ref={sliderRef} className="keen-slider">
          {products &&
            products.map((item, index) => {
              return (
                <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                  <div className="w-full max-w-[300px]">
                    {withGroup && !!item.groups?.length ? (
                      <MultiProductsBox
                        multiProducts={item.groups}
                        staticLink={'/'}
                        title={item.title}
                        description={item.description}
                        image={item.img}
                        arabic={arabic}
                        lang={lang}
                        alt={item?.alt}
                        currency={currency ? currency : 'AUD'}
                        showMultiProductsOnRow
                      />
                    ) : (
                      <ProductBox
                        stateVariableObj={{ productName: item.name }}
                        product={item}
                        arabic={arabic}
                        lang={lang}
                        // linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : '!font-normal'}
                        linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                        isStaticProduct={item.isStaticProduct}
                        staticLink={item.staticLink}
                        staticLinkText={item.staticLinkText}
                        currency={currency ? currency : 'AUD'}
                        titleClasses={`uppercase`}
                        isPalestineRefugee={isPalestineRefugee}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {showDots && products?.length > 1 && (
          <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
            {loaded && instanceRef.current && (
              <div className="flex justify-center items-center relative w-full">
                <Arrow left onClick={e => e.stopPropagation() || instanceRef.current?.prev()} disabled={currentSlide === 0} />
                {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    <button
                      key={`q${idx}`}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={
                        'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                        (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')
                      }
                    ></button>
                  );
                })}
                <Arrow
                  onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                  disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default TabContent;
