// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          welcome: 'Welcome',
          description: 'This is a multi-language example.',
        },
      },
      fr: {
        translation: {
          welcome: 'Bienvenue',
          description: 'Ceci est un exemple multilingue.',
        },
      },
    },
    fallbackLng: 'en', // Use English if detection fails
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      // Options for language detection
      order: [
        'querystring', // check for ?lng=fr
        'cookie', // check for lang=fr
        'localStorage', // check for lang=fr
        'navigator', // check navigator.language
        'htmlTag', // check <html lang="fr">
        'path', // check path after /
        'subdomain', // check subdomain
      ],
      caches: ['cookie', 'localStorage'], // caches languages
    },
  });

export default i18n;
