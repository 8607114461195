import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { selectSelectedCurrency } from '../../../store/user';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import AppealGivingProductBox from './appealGivingProductBox';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import { toast } from 'react-toastify';
import services from '../../../services';
// import Sticky from 'react-sticky-el';
// import CustomButton from '../../../components/general/button';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
// import { useNavigate } from 'react-router-dom';
// import { CloseIcon } from '../../../components/general/customIcons';

const AppealLevelGiving = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { givingProducts, lang = 'en', title, handleSelectedGivingProps } = props;
  let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState([0, 0, 0, 0]);
  const showGivingProducts = givingProducts && givingProducts.length > 0;

  const [currentSlide, setCurrentSlide] = useState(0);
  const MutationPlugin = slider => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        slider.update();
      });
    });
    const config = { childList: true };

    slider.on('created', () => {
      observer.observe(slider.container, config);
    });
    slider.on('destroyed', () => {
      observer.disconnect();
    });
  };
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      mode: 'snap',
      loop: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      breakpoints: {
        '(min-width: 320px)': {
          slides: { perView: 1.3, spacing: 20 },
        },
        '(min-width: 768px)': {
          slides: { perView: 2, spacing: 10 },
        },
        '(min-width: 1024px)': {
          slides: { perView: 3, spacing: 10 },
        },
        '(min-width: 1280px)': {
          slides: { perView: 4, spacing: 10 },
        },
      },
      slides: {
        origin: 'center',
        // perView: 4,
        // spacing: 8,
      },
      // eslint-disable-next-line no-use-before-define
    },
    [MutationPlugin],
  );
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */

  const handleAddQty = (prd, index) => {
    let tempPrd = Object.assign({}, prd, {
      quantity: 1,
    });
    addProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true });
    handleSelectedGiving();
  };
  const handleReduceQty = (prd, index) => {
    if (prd.quantity > 1) {
      let tempPrd = Object.assign({}, prd, {
        quantity: -1,
      });
      editProduct({ product: tempPrd, currency: selectedCurrency, justAddToLocalStorage: true });
      handleSelectedGiving();
    } else {
      handleRemoveProduct(prd);
    }
  };
  const handleAddToGiving = product => {
    let tempProduct = {
      ...product.prices.reduce((prev, val) => ({ ...prev, [val.name.toLowerCase()]: val.data.amount }), {}),
      creator: product.creator,
      name: product.name,
      description: product.description,
      custom_amount: product.customDonation ? 'YES' : 'NO',
      image_link: product.webImage?.[0],
      recurring: 'Allowed',
    };
    const customAmount = product.customDonation ? 100 : 1;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
    });

    addProduct({ product: tempProduct, currency: selectedCurrency, justAddToLocalStorage: true });
    handleSelectedGiving();
  };
  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];
    // let tempSelectedGiving = [];
    // let tempTotalGiving = 0;
    givingProducts.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });

    setProducts(temp);
    handleSelectedGivingProps();
  };

  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    setProducts(givingProducts);
  }, [givingProducts, selectedCurrency]);

  /* -------------------------------------------------------------------------- */

  if (!showGivingProducts) {
    return null;
  }
  return (
    <>
      <div className="flex flex-col w-full justify-center mb-0 md:mb-6" ref={contentRef}>
        <h2 className={`text-center text-DarkerBlue text-lg md:text-3xl w-full font-bold mb-2`}>{title}</h2>
      </div>
      <div ref={contentRef} className="md:px-4 overflow-hidden- max-w-full relative">
        <div className="md:w-[95%] mx-auto 2xl:w-full flex-wrap justify-center items-center relative">
          {products && products.length > 1 && (
            <div className="hidden md:flex">
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[40%] -left-[18px] md:-left-[36px] z-10 rounded-full ${
                    currentSlide === 0 ? 'cursor-not-allowed bg-[#e6e6e6] text-[#9e9e9e]' : 'cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue'
                  }`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                >
                  <LiaAngleLeftSolid size={25} />
                </div>
              )}
              {instanceRef.current && (
                <div
                  className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[40%] -right-[18px] md:-right-[36px] z-10 rounded-full cursor-pointer bg-LightBlue bg-opacity-10 text-LightBlue`}
                  onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                >
                  <LiaAngleRightSolid size={25} />
                </div>
              )}
            </div>
          )}

          <div ref={sliderRef} className="keen-slider">
            {products &&
              products.map((item, index) => {
                return (
                  <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center py-4">
                    <div className="w-full max-w-[355px]">
                      <AppealGivingProductBox
                        product={item}
                        lang={lang}
                        currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        onAddQty={() => handleAddQty(item, index)}
                        onReduceQty={() => handleReduceQty(item, index)}
                        onAddToGiving={() => handleAddToGiving(item, index)}
                        basket={basketStatesFromLocalStorage}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          {products && products.length > 1 && (
            <div className="flex md:hidden bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
              {loaded && instanceRef.current && instanceRef.current.track.details && (
                <div className="flex justify-center items-center relative w-full">
                  {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={`q${idx}`}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={
                          'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                          (currentSlide === idx ? ' bg-LightBlue hover:opacity-60' : 'bg-[#C3C3C3]')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AppealLevelGiving;
