import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import CustomButton from '../../../components/general/button';
import ShowCurrencies from '../../../utils/showCurrencies';
import ConvertUSD from '../../../utils/convertUSD';
import Sticky from 'react-sticky-el';
import SocialBox from '../../fundraising/components/socialBox';
import moment from 'moment';
import { endDateOfAppealCampaign, endDateOfLimbsAppealCampaign } from '../details';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getYoutubeVideoId } from '../../../utils/helperFunctions';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import DetailsWidget from '../detailsWidget';

function handleConvertToDate(dateString) {
  const [day, month, year] = dateString.split('-');
  return new Date(year, month - 1, day);
}

const AppealCurrentProduct = ({ product, campaign }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const currency = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  /* ------------------------------ Start methods ----------------------------- */
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };
  const handleDaysLeft = () => {
    var now = moment(new Date());
    var after = moment(new Date(campaign === 'limbs-of-hope' ? endDateOfLimbsAppealCampaign : endDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
    var timeDifference = after.diff(now, 'days');
    return timeDifference;
  };

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    currency ? setSelectedCurrency(currency) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);
  /* -------------------------------------------------------------------------- */

  const { videoUrl, webImage, mobileImage, altTag, titleTag, name, description, moreDescription, targetEndDate, donationCount } = product || {};

  const image = isMobile ? mobileImage?.[0] || webImage?.[0] : webImage?.[0];
  const targetAmount = product?.targetAmount || 0;
  const showDaysLeft = handleDaysLeft(handleConvertToDate(targetEndDate ?? '')) > 1;
  const isGroupFund = product?.type === 'GROUP_FUND';
  const showSupportersCount = isGroupFund ? product?.groupTotalPayment?.totalDonation > 0 : product?.donationCount > 0;
  const supportestCount = isGroupFund ? product?.groupTotalPayment?.totalDonation : product?.donationCount;
  const showRaisedAmount = isGroupFund ? product?.groupTotalPayment?.totalPayment > 0 : product?.goal_progress?.total > 0;
  const raisedAmount = (isGroupFund ? product?.groupTotalPayment?.totalPayment : product?.goal_progress?.total) || 0;
  const hasTarget = isGroupFund || (targetAmount > 0 && showDaysLeft);
  const title = isGroupFund ? product?.groupDisplayName : product?.name;

  return (
    <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-8 md:py-8">
      <div className="col-span-2 md:col-span-1">
        <div
          className={`basis-full xs:aspect-w-16  xs:aspect-h-9 sm:h-[300px] md:h-[600px] lg:h-[890px] !max-h-[300px] min-h-fit md:!max-h-[450px]   md:!rounded-lg overflow-hidden`}
        >
          {product ? (
            videoUrl ? (
              <iframe
                className="video"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${getYoutubeVideoId(videoUrl)}?autoplay=0&feature=oembed`}
              />
            ) : image ? (
              <LazyLoadImage
                alt={altTag}
                title={titleTag}
                effect="blur"
                width={`100%`}
                delayTime={500}
                className="rounded"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/general/logo-replace.png';
                }}
                src={image}
              />
            ) : (
              <img className="w-full" src="/images/general/logo-replace.png" alt="" />
            )
          ) : (
            <Skeleton height={450} />
          )}
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 px-4 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0">
        {title ? (
          <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">{title}</h3>
        ) : (
          <Skeleton height={48} width={400} />
        )}
        <div className="w-full relative">
          <div className={`my-2 relative overflow-hidden h-auto mb-4`}>
            {description ? (
              <p className={`text-[#78716C] text-xs- sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}>{description}</p>
            ) : (
              <Skeleton height={79} />
            )}
          </div>
        </div>
        {product ? (
          <div className="flex gap-4 items-center">
            {moreDescription && (
              <CustomButton
                onClick={() => setShowInfo(!showInfo)}
                title={'Click to know more'}
                icon={
                  <ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 ${showInfo ? ' transform rotate-180' : ''}`} />
                }
                type="button"
                className={`!bg-LightBlue !text-[10px] !h-8`}
              />
            )}
            <div className="w-fit">
              <SocialBox>
                <div
                  className={`rounded-md !bg-DarkerBlue flex items-center justify-center text-center font-['Montserrat'] font-bold px-4 text-[12px] md:text-[14px] h-[32px] transition duration-150 ease-in-out uppercase`}
                >
                  Share
                </div>
              </SocialBox>
            </div>
          </div>
        ) : (
          <Skeleton height={32} width={180} />
        )}

        <div
          className={`flex flex-col overflow-hidden w-full bg-stone-100- rounded-md ${
            showInfo ? 'min-h-[600px] py-4 md:py-8' : 'h-0 py-0'
          } transition-all duration-200`}
        >
          <div dangerouslySetInnerHTML={{ __html: moreDescription }} />
          <CustomButton
            onClick={() => setShowInfo(!showInfo)}
            title={'Close'}
            icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 transform rotate-180`} />}
            type="button"
            className={`!bg-white !text-LightBlue !text-[10px] !h-8 shadow-md`}
          />
        </div>
        <div className="w-full flex-col flex justify-center pt-2 md:pt-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex flex-col w-full">
              {product ? (
                hasTarget &&
                showRaisedAmount && (
                  <p className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold">
                    {ShowCurrencies(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, raisedAmount, false)), false)}
                    <span className="text-xs ml-0.5">{selectedCurrency}</span>
                  </p>
                )
              ) : (
                <Skeleton height={24} width={60} />
              )}
              {product ? (
                hasTarget && (
                  <div className="flex items-center gap-1">
                    <span className="text-sm inline-block lg:w-auto">Raised of </span>
                    <strong className="text-DarkerBlue font-bold">
                      {ShowCurrencies(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, targetAmount, false)))}
                    </strong>
                    <span className="text-sm inline-block lg:w-auto">goal</span>
                  </div>
                )
              ) : (
                <Skeleton height={20} width={300} />
              )}
            </div>
          </div>
          {hasTarget && showRaisedAmount && (
            <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
              <div
                className={`bg-[#F60362]`}
                style={{
                  width: `${handlePercentage(ConvertUSD(selectedCurrency, raisedAmount, false), ConvertUSD(selectedCurrency, targetAmount, false))}`,
                }}
              />
            </div>
          )}
          {product ? (
            hasTarget && (
              <div className="flex w-full text-xs">
                {showSupportersCount && (
                  <>
                    <strong className="text-DarkerBlue font-bold mr-0.5">{supportestCount}</strong>
                    <span className="inline-block lg:w-auto ">Supporters</span>
                  </>
                )}
                {showSupportersCount && showDaysLeft && <span>,</span>}
                {showDaysLeft && (
                  <>
                    <strong className="text-DarkerBlue font-bold ml-1 mr-0.5">{handleDaysLeft(handleConvertToDate(targetEndDate ?? ''))}</strong>
                    <span className="inline-block lg:w-auto">days left</span>
                  </>
                )}
              </div>
            )
          ) : (
            <Skeleton height={12} width={160} />
          )}
        </div>
        <div className="w-full flex flex-col gap-3 mt-4 md:mt-8">
          {product ? (
            <>{product && <DetailsWidget defaultProduct={product} isLoaded />}</>
          ) : (
            <>
              <Skeleton height={43} width={140} borderRadius={999999} className="mr-2" />
              <Skeleton height={43} width={85} borderRadius={999999} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AppealCurrentProduct;
