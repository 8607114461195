import { Link } from 'react-router-dom';
import { generateLinkWithLang } from '../../../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';

const Highlights = props => {
  const { data } = props;
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className="w-full bg-[#093484] min-h-[110px] md:min-h-[120px] flex px-2 overflow-x-scroll md:overflow-x-auto">
      <div className="md:mx-auto grid grid-flow-col gap-1 md:min-w-[955px] justify-center">
        {data.map((item, index) => {
          return (
            <Link
              key={`links${index}`}
              to={generateLinkWithLang(item.fields.url.value, language)}
              className={`min-w-max flex flex-col px-1 justify-center items-center overflow-hidden`}
            >
              <img
                src={window.innerWidth > 500 ? item.fields?.image?.value : item.fields?.image?.conversation?.mobile ?? item.fields?.image?.value}
                alt={item.fields?.image?.custom_attributes?.alt_tag}
                title={item.fields?.image?.custom_attributes?.title_tag}
                className={`max-w-full w-[56px] md:w-[68px] lg:w-[78px] h-[56px] md:h-[68px] lg:h-[78px] object-center object-cover circle_animation rounded-full`}
              />
              <p className="text-center text-white text-[12px] mt-1 whitespace-nowrap">{item.fields.title.value}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Highlights;
