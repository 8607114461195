import axios from 'axios';

const Base = process.env.REACT_APP_V2_BASE_URL;

function getContent(url, isCurrency = false, language = 'en') {
  const urlParams = new URLSearchParams(window.location.search);
  const ph = urlParams.get('ph');
  return axios.get(`${Base}${url}${isCurrency ? '&' : '?'}lang=${language}${ph ? `&ph=${ph}` : ''}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export function getProducts(url, language = 'en') {
  return axios.get(`${Base}${url}&lang=${language}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export function getCurrencies(currencies = [], language = 'en') {
  const query = currencies.map(item => `filters[id][]=${item}&`).join('');
  return axios.get(`${Base}/currencies/?${query}&lang=${language}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export function getFaqs(url, language = 'en') {
  return axios.get(`${Base}${url}&lang=${language}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export function getBasket(token) {
  return axios.get(`${Base}/baskets/get?token=${token}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export function deleteBasketItem(obj) {
  return axios.post(`${Base}/baskets/delete`, obj, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export function addItemToBasket(basketItem) {
  return axios.post(`${Base}/baskets/add`, basketItem, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export default {
  getContent,
  getCurrencies,
  getProducts,
};
