import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../../../store/user';
import ReactSelect from 'react-select';
import creditImg from '../../../../../images/payment/credit-cards.png';
import useHandleAddProduct from '../../../../../utils/handleAddProduct';
import { selectProducts } from '../../../../../store/products';
import { CustomButton } from '../../../../../components/general';
import SelectCurrency from '../../../../../components/general/selectCurrency';
import { setSelectedCurrencyInStore } from '../../../../../store/user';
import ShowCurrencies from '../../../../../utils/showCurrencies';

const styles = {
  menuList: base => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

const DonationWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { className, data, currencies, products, productsList, currentProduct, setCurrentProduct } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const hasSuggestions = data[0]?.prices?.value?.length > 0;
  const suggestedPrices = data[0]?.prices?.value ?? [];
  const [productAmount, setProductAmount] = useState(hasSuggestions ? '' : data[0].value?.value ?? 100);
  const [selectedPrice, setSelectedPrice] = useState(suggestedPrices[0]);
  const dispatch = useDispatch(state => state.currency);

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
    dispatch(setSelectedCurrencyInStore(currency.value));
  };
  const handleProductSelect = product => {
    setCurrentProduct(product);
  };
  const handleAddNewProduct = () => {
    const product = productsList.find(p => p.id === currentProduct.value);
    let tempProduct = {
      ...product.prices.reduce((prev, val) => ({ ...prev, [val.name.toLowerCase()]: val.data.amount }), {}),
      creator: product.creator,
      name: product.name,
      description: product.description,
      custom_amount: product.customDonation ? 'Yes' : 'No',
      image_link: product.webImage?.[0],
      recurring: 'Allowed',
    };
    const amount = selectedPrice ? selectedPrice : productAmount;
    let customAmount = Number(amount) ? Number(amount) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
    });

    addProduct({
      product: tempProduct,
      currency: currentCurrency,
    });
  };

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('USD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return (
    <div
      className={`w-full bg-[#00a3da] px-3 md:px-6 py-3 md:py-6 flex items-center ${className} sticky top-16 md:top-[64px] lg:top-[96px] w-full z-30`}
    >
      <div className="w-full md:container md:mx-auto ">
        <div
          className={`grid grid-cols-12 gap-2 lg:gap-2 md:contaier mx-auto lg:px-2 ${
            hasSuggestions ? 'lg:grid-cols-[1.5fr_1fr_1.5fr_1fr_1fr]' : 'max-w-[1000px] lg:grid-cols-[1.5fr_1fr_1fr_1fr]'
          }`}
        >
          <div className="col-span-5 lg:col-span-1 order-1 flex items-center bg-white rounded-md h-[38px]">
            <div className="min-w-max">
              <SelectCurrency currencies={currencies} onChangeCurrency={e => handleCurrency(e)} classNamePrefix="home_select" />
            </div>
            <input
              value={productAmount}
              className="h-[38px] text-[#777] w-full truncate p-2 rounded-lg text-sm pl-1 md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
              type="number"
              min={'0'}
              onChange={e => {
                const regex = /^(?:[1-9]\d*)?$/;
                if (regex.test(e.target.value)) {
                  setProductAmount(e.target.value);
                  setSelectedPrice('');
                }
              }}
              placeholder="Amount"
            />
          </div>
          {hasSuggestions && (
            <div className="col-span-12 lg:col-span-1 order-3 lg:order-2 grid grid-cols-12 lg:flex gap-2">
              {suggestedPrices.map((price, index) => {
                return (
                  <div key={`prices${index}`} className="col-span-3">
                    <div
                      className={`h-[38px] text-sm transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[60px] rounded-md ${
                        selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                      }`}
                      onClick={() => {
                        setSelectedPrice(price);
                        setProductAmount('');
                      }}
                    >
                      {ShowCurrencies(currentCurrency, price, false)}
                      <span className={`uppercase ml-1 text-xs`}>{currentCurrency}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="col-span-7 lg:col-span-1 order-2 lg:order-3">
            <ReactSelect
              options={products}
              className="max-h-[48px] [&>div]:!rounded-md"
              classNamePrefix="home-select"
              defaultValue={products && products[0]}
              value={currentProduct}
              isSearchable={false}
              styles={styles}
              onChange={e => handleProductSelect(e)}
            />
          </div>

          <div className="col-span-6 lg:col-span-1 order-4 rounded-md px-3 h-[38px] bg-[#80ceff] flex items-center justify-center">
            <img
              src={creditImg}
              alt="MATW"
              className="w-full h-auto w-[260px]- h-[20px]- sm:w-[254px]- sm:h-[28px]- md:w-[221px]- md:h-[25px]- lg:w-[125px]- lg:h-[14px]- xl:w-full- xl:h-auto-"
            />
          </div>

          <div className="order-5 col-span-6 lg:col-span-1">
            <CustomButton
              onClick={() => {
                handleAddNewProduct();
              }}
              disabled={!products?.length}
              title={'Quick Donate'}
              className={`w-full hover:!bg-[#F60362] !h-[38px] !px-2 blob red`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationWidget;
