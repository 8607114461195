import orphanIcon from '../../../images/home/orphans.png';
import emergencyIcon from '../../../images/home/emergency.png';
import foodPackIcon from '../../../images/home/food-packs.png';
import reliefIcon from '../../../images/home/relief.png';
// import hotMealIcon from '../../../images/home/hot-meals.png';
import CustomButton from '../../../components/general/button';

const AppealImpact = ({ lang = 'en', document, usedOnTab }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const latestImpacts = [
    {
      title: 'FOOD, Nutrition & Water',
      img: foodPackIcon,
      count: '54,623,420',
    },
    {
      title: 'Shelter & Clothing',
      img: orphanIcon,
      count: '67,892',
    },
    {
      title: 'Health and Hygiene',
      img: emergencyIcon,
      count: '846,820',
    },
    {
      title: 'General Relief',
      img: reliefIcon,
      count: '152,290',
    },
  ];
  /* -------------------------------------------------------------------------- */

  return (
    <section className={`md:container md:mx-auto px-4 md:px-0 ${usedOnTab ? 'py-2 md:py-4' : 'py-8 md:py-12'}`} dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <h2
        className={`text-center mb-2 md:mb-4 ${usedOnTab ? 'text-lg md:text-3xl' : 'text-2xl md:text-4xl'} ${
          lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
        } text-[#253B7E]`}
      >
        Our Latest Impact
      </h2>
      <div className="w-full grid grid-cols-4 gap-2 md:gap-4">
        {latestImpacts.map((item, index) => {
          return (
            <div
              key={`impacts${index}`}
              className={`col-span-2 ${usedOnTab ? 'md:col-span-1' : 'md:col-span-2'} flex flex-col items-center rounded-lg shadow-lg py-4 md:py-8`}
            >
              <img src={item.img} alt={item.title} className={usedOnTab ? 'w-1/5' : 'auto'} />
              <div className="flex flex-col items-center text-center mt-2">
                <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>
                  {item.count}
                </h4>
                <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                  {item.title}
                </small>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full flex justify-center mt-8 mb-5">
        {document && <CustomButton link={document} target="_blank" title={'Download Impact Report'} className={`!rounded-full`} />}
      </div>
    </section>
  );
};
export default AppealImpact;
